// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-custom-jsx": () => import("./../../../src/templates/Custom.jsx" /* webpackChunkName: "component---src-templates-custom-jsx" */),
  "component---src-templates-gallery-jsx": () => import("./../../../src/templates/Gallery.jsx" /* webpackChunkName: "component---src-templates-gallery-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-package-jsx": () => import("./../../../src/templates/Package.jsx" /* webpackChunkName: "component---src-templates-package-jsx" */),
  "component---src-templates-services-jsx": () => import("./../../../src/templates/Services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */)
}

